<template>
  <div style="min-height: 100vh">
    <v-row justify="center" align="center" style="min-height: 80vh">
      <v-col cols="12" lg="5" md="5" xl="5" sm="5">
        <v-form @submit.prevent="checkPass()">
          <v-card class="pa-5">
            <v-card-text>
              <v-row no-gutters>
                <v-col class="text-center">
                  <img src="@/assets/new-logo-gdl.svg" width="300px" />
                </v-col>
              </v-row>
              <v-text-field
                class="mt-6"
                v-model="user.email"
                label="Email"
                dense
                type="email"
                outlined
                persistent-placeholder
                :error-messages="emailError | localize"
              >
              </v-text-field>
              <v-text-field
                v-model="user.password"
                label="Password"
                dense
                type="password"
                outlined
                persistent-placeholder
                :error-messages="passwordError | localize"
                :hide-details="!passwordError.length"
              >
              </v-text-field>
              <span style="color: red" v-if="messages">{{ messages }}</span>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn dark color="#e62076" @click="checkPass()">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import SupportService from "@/requests/SupportPanel/SupportService.js";

export default {
  mixins: [validationMixin],
  data: () => ({
    messages: "",
    user: {},
  }),
  validations: {
    user: {
      password: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  methods: {
    async checkPass() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const data = {
            login: this.user.email,
            password: this.user.password,
          };
          let res = await SupportService.loginChambers(data);

          localStorage.chambersToken = res.result;
          this.$emit("onLogin");
        }
      } catch (e) {
        console.log(e.response)
        if (e.response.status == 404) {
            this.messages = 'Invalid credentials'
        }
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.user.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      !this.$v.user.password.required &&
        errors.push("sign_up_validation_password_required");
      return errors;
    },
  },
};
</script>