<template>
  <v-dialog max-width="810px" scrollable v-model="visibility">
    <v-card style="position: relative" max-height="870px">
      <v-icon
        style="position: absolute; right: 10px; top: 5px"
        large
        @click="visibility = false"
        >mdi-close</v-icon
      >
      <v-card-title>Order Details</v-card-title>
      <v-card-text class="px-10">
        <v-row class="align-center" no-gutters>
          <v-col class="col-12 pt-5">
            <v-row justify="space-between">
              <div>Order number:</div>
              <div class="font-weight-bold">{{ item.number }}</div>
            </v-row>
            <v-row justify="space-between">
              <div>Purchase Date:</div>
              <div class="font-weight-bold">
                {{
                  new Date(item.created_at)
                    .toLocaleDateString("en-GB", {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .replaceAll("/", "-")
                }}
                {{ formatCreateOrderDate }}
              </div>
            </v-row>
            <v-row justify="space-between">
              <div>Name/Surname:</div>
              <div class="font-weight-bold">
                {{ item.user.first_name || "--" }}
                {{ item.user.last_name || "--" }}
              </div>
            </v-row>
            <v-row justify="space-between">
              <div>Email:</div>
              <div class="font-weight-bold">{{ item.user.email || "--" }}</div>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-for="(citypass, index) in item.citypasses"
          :key="index"
          class="mt-7"
          align="center"
        >
          <v-col class="col-12 px-0 pt-0"><v-divider></v-divider></v-col>
          <v-col class="col-12 col-sm-7">
            <v-row justify="space-between">
              <div>Name/type of pass:</div>
              <div class="font-weight-bold">
                {{
                  locale == "en" ? citypass.title_en : citypass.title_es || "--"
                }}
              </div>
            </v-row>
            <v-row justify="space-between">
              <div>Start/end date of the pass:</div>
              <div class="font-weight-bold">
                {{
                  citypass.started_at.substr(8, 2) +
                    "-" +
                    citypass.started_at.substr(5, 2) +
                    "-" +
                    citypass.started_at.substr(0, 4) || "--"
                }}/{{
                  citypass.ended_at.substr(8, 2) +
                    "-" +
                    citypass.ended_at.substr(5, 2) +
                    "-" +
                    citypass.ended_at.substr(0, 4) || "--"
                }}
              </div>
            </v-row>
            <v-row justify="space-between">
              <div>Adults:</div>
              <div class="font-weight-bold">
                {{ citypass.count_adult }}
              </div>
            </v-row>
            <v-row justify="space-between">
              <div>Kids:</div>
              <div class="font-weight-bold">
                {{ citypass.count_kid }}
              </div>
            </v-row>
            <v-row class="pt-3 justify-center">
              <div class="font-weight-bold">Attractions included:</div>
            </v-row>
            <v-row
              justify="space-between"
              v-for="(attraction, index) in citypass.attractions"
              :key="index"
              align="center"
            >
              <v-col cols="5" class="px-0">
                <div>{{ attraction.title }}</div>
              </v-col>
              <v-col cols="7" class="px-0">
                <v-row class="py-0 my-0" align="center" justify="end">
                  {{ attraction.status }}
                  <div
                    class="ml-2"
                    v-if="!attraction.event_uuid"
                    style="font-size: 11px"
                  >
                    ({{
                      new Date(
                        attraction.started_at.substr(0, 10)
                      ).toLocaleDateString("en-gb", ) +
                      "-" +
                      new Date(
                        attraction.ended_at.substr(0, 10)
                      ).toLocaleDateString("en-gb", )
                    }})
                  </div>
                  <div
                    class="ml-2"
                    v-if="attraction.event_uuid"
                    style="font-size: 11px"
                  >
                    (Slot:
                    {{
                      new Date(
                        adaptDate(attraction.event_started_at)
                      ).toLocaleTimeString("en-GB", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }}
                    -
                    {{
                      new Date(
                        adaptDate(attraction.event_ended_at)
                      ).toLocaleTimeString("en-GB", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }},
                    {{
                      new Date(attraction.event_ended_at).toLocaleDateString(
                        locale == "en" ? "en-GB" : "es-ES",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      )
                    }})
                  </div>
                  <v-btn
                    @click="
                      selectedItem = {
                        order: item,
                        citypass: citypass,
                        attraction: attraction,
                      };
                      !attraction.event_uuid
                        ? (datesDialog = true)
                        : (showBookingAttractionModal = true);
                    "
                    class="ml-1"
                    x-small
                    icon
                    ><v-icon>mdi-cog</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-12 col-sm-5">
            <v-row class="align-center justify-end pl-5">
              <Qrcode :text="citypass.qr_code"> </Qrcode>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 px-0"><v-divider></v-divider></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <set-dates-dialog
      :item="selectedItem"
      @close="datesDialog = false"
      @changedDates="changedDates"
      :visible="datesDialog"
      v-if="datesDialog"
    />
    <booking-attraction-modal
      v-if="showBookingAttractionModal"
      :visible="showBookingAttractionModal"
      :attraction="selectedItem.attraction"
      :startCitypassDate="selectedItem.citypass.started_at.substr(0, 10)"
      :endCitypassDate="
        setEndDate(selectedItem.citypass.started_at.substr(0, 10))
      "
      :adultNum="selectedItem.citypass.count_adult"
      :childNum="selectedItem.citypass.count_kid"
      @chooseTime="chooseTimeForBookingAttraction"
      :selectedEvent="selectedItem.attraction.event_uuid"
      @close="showBookingAttractionModal = false"
    />
  </v-dialog>
</template>

<script>
import Qrcode from "vue-qr-generator";
import userSettings from "@/mixins/userSettingsMixin.js";
import setDatesDialog from "./setDatesDialog.vue";
import BookingAttractionModal from "@/components/OurAttractions/bookingAttractionModal.vue";
import SupportService from "@/requests/SupportPanel/SupportService.js";
import moment from "moment-timezone";
import dateMixin from "../../../mixins/dateMixin";
export default {
  mixins: [userSettings, dateMixin],
  components: {
    Qrcode,
    setDatesDialog,
    BookingAttractionModal,
  },
  data: () => ({
    datesDialog: false,
    showBookingAttractionModal: false,
    selectedItem: {},
    formatCreateOrderDate: "",
  }),
  props: {
    visible: {
      require: true,
    },
    item: {
      require: true,
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  mounted() {
    let dateInZeroTZ = moment(this.item.created_at).tz("Etc/UTC");
    const dateInGuadalajaraTZ = dateInZeroTZ
      .clone()
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.formatCreateOrderDate =
      dateInGuadalajaraTZ._d.toLocaleTimeString("en-GB");
  },
  methods: {
    changedDates(uuid) {
      this.$emit("changedDates", uuid);
    },
    async chooseTimeForBookingAttraction(time) {
      this.showBookingAttractionModal = false;
      const params = {
        parent_uuid: this.selectedItem.citypass.parent_uuid,
        attraction_uuid: this.selectedItem.attraction.uid,
        event_uuid: time.uuid,
      };
      let res = await SupportService.setDates(params);
      console.log(res);

      this.changedDates(this.selectedItem.order.uuid);
    },
    setEndDate(start_date) {
      // Creating a new Date object from the provided start date.
      const currentDate = new Date(start_date);

      // Getting the current month and year.
      let currentMonth = currentDate.getMonth();
      let currentYear = currentDate.getFullYear();

      // Adding 2 months to the current month.
      currentMonth += 2;

      // Adjusting the year if necessary.
      if (currentMonth > 11) {
        currentMonth -= 12;
        currentYear++;
      }

      // Setting the new date by updating the month and year.
      currentDate.setMonth(currentMonth);
      currentDate.setFullYear(currentYear);

      // Returning the calculated end date.
      return currentDate;
    },
  },
};
</script>

<style scoped>
.text-truncate {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>