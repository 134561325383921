<template>
  <div style="min-height: 100vh">
    <div class="mt-15">
      <v-snackbar color="error" v-model="error_notify.visible" bottom left>
        {{ error_notify.text }}
      </v-snackbar>
      <v-card :loading="loading">
        <v-card-text :class="{ disabled: loading }">
          <v-row>
            <v-col class="ml-3">
              <v-radio-group v-model="updatingRadio" row mandatory>
                <template v-slot:label>
                  <div style="font-size: 16px !important; font-weight: 700">
                    {{ "refresh_label" | localize }}
                  </div>
                </template>
                <v-radio
                  value="Automatic"
                  :label="'refresh_auto_label' | localize"
                >
                </v-radio>
                <v-radio
                  value="Manual"
                  :label="'refresh_manual_label' | localize"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-spacer />
            <v-col class="col-12 col-sm-4">
              <v-text-field
                append-icon="mdi-magnify"
                dense
                outlined
                label="Search"
                v-model="searchQuery"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="supportHeaders"
            :items="supportItems"
            :items-per-page="pagination.per_page"
            class="elevation-0"
            hide-default-footer
          >
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.created_at="{ item }">
              {{
                item.created_at
                  ? item.created_at.substr(8, 2) +
                    "-" +
                    item.created_at.substr(5, 2) +
                    "-" +
                    item.created_at.substr(0, 4)
                  : "--"
              }}
              {{ formatTime(item.created_at) }}
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.user.first_name="{ item }">
              {{ item.user.first_name || "--" }}
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.user.last_name="{ item }">
              {{ item.user.last_name || "--" }}
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.user.email="{ item }">
              {{ item.user.email || "--" }}
            </template>
            <!-- eslint-disable-next-line  -->
            <template v-slot:item.btn="{ item }">
              <v-btn
                @click="
                  selectedItem = item;
                  detailsInfoDialog = true;
                "
                text
                style="text-transform: none"
                color="#e62076"
              >
                View
              </v-btn>
            </template>
            <!-- eslint-disable-next-line  -->
            <template #footer.prepend>
              <v-spacer />
            </template>
          </v-data-table>
          <v-row>
            <v-spacer />
            <v-col class="col-6">
              <v-pagination
                v-model="pagination.page"
                :length="pagination.length"
                color="#6abaa4"
                class="mt-5"
                :disabled="loading"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <details-info-dialog
      v-if="detailsInfoDialog"
      :visible="detailsInfoDialog"
      :item="selectedItem"
      @close="detailsInfoDialog = false"
      @changedDates="changedDates"
    />
  </div>
</template>

<script>
import detailsInfoDialog from "./components/detailsInfoDialog.vue";
import SupportService from "@/requests/SupportPanel/SupportService.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {
  components: {
    detailsInfoDialog,
  },
  data: () => ({
    supportHeaders: [],
    supportItems: [],
    selectedItem: {},
    detailsInfoDialog: false,
    pagination: {
      page: 1,
    },
    loading: false,
    searchQuery: "",
    error_notify: {
      visible: false,
      text: "",
    },
    updatingRadio: "Automatic",
    updateTimeout: null,
  }),
  mounted() {
    this.renderSupportTable();
    this.getSupportItems();
  },
  methods: {
    changedDates(uuid) {
      console.log(uuid);
      this.getSupportItems(uuid);
    },
    async getSupportItems(uuid = "") {
      try {
        this.loading = true;
        let params = {
          page: this.pagination.page,
        };
        if (this.searchQuery) {
          params.order_number = this.searchQuery;
        }
        let res = await SupportService.getOrders(params);

        if (res.data) {
          this.supportItems = res.data.items;
          this.pagination = {
            per_page: parseInt(res.data.pagination.per_page),
            page: parseInt(res.data.pagination.page),
            total: parseInt(res.data.pagination.total),
          };

          if (this.pagination.total % this.pagination.per_page == 0) {
            this.pagination.length = parseInt(
              this.pagination.total / this.pagination.per_page
            );
          } else {
            this.pagination.length =
              parseInt(this.pagination.total / this.pagination.per_page) + 1;
          }

          if (uuid) {
            console.log(uuid);
            this.selectedItem = res.data.items.find((el) => el.uuid == uuid);
            console.log(this.selectedItem);
          }

          if (this.updatingRadio == "Automatic") {
            clearTimeout(this.updateTimeout);

            this.updateTimeout = setTimeout(() => {
              this.getSupportItems();
            }, 60000);
          }
        } else {
          this.supportItems = [];
        }
      } catch (e) {
        this.supportItems = [];
        this.error_notify.text = e;
        this.error_notify.visible = true;
        if (e.response.status == 403) {
          this.$emit("onInvalidToken");
        }
      } finally {
        this.loading = false;
      }
    },
    getItemsBySearchQuery: _.debounce(async function () {
      this.pagination.page = 1;
      this.getSupportItems();
    }, 700),
    renderSupportTable() {
      this.supportHeaders = [
        {
          text: "Order Number",
          align: "left",
          sortable: false,
          value: "number",
        },
        {
          text: "Purchase date",
          align: "left",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "user.first_name",
        },
        {
          text: "Surname",
          align: "left",
          sortable: false,
          value: "user.last_name",
        },
        {
          text: "Email",
          align: "left",
          sortable: false,
          value: "user.email",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "btn",
          width: "10%",
        },
      ];
    },
    formatTime(time) {
      let dateInZeroTZ = moment(time).tz("Etc/UTC");
      const dateInGuadalajaraTZ = dateInZeroTZ
        .clone()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
      return dateInGuadalajaraTZ._d.toLocaleTimeString("en-GB");
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getSupportItems();
      },
    },
    searchQuery: {
      handler() {
        this.getItemsBySearchQuery();
      },
    },
    updatingRadio: {
      handler() {
        if (this.updatingRadio == "Manual") {
          clearTimeout(this.updateTimeout);
        } else {
          this.updateTimeout = setTimeout(() => {
            this.getSupportItems();
          }, 60000);
        }
      },
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}
</style>