<template>
  <v-main>
    <v-container>
      <support-panel-component />
    </v-container>
  </v-main>
</template>

<script>
import SupportPanelComponent from '@/components/SupportPanel/SupportPanelComponent'

export default {
    components: {
        SupportPanelComponent,
    }
}
</script>