var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"mt-15"},[_c('v-snackbar',{attrs:{"color":"error","bottom":"","left":""},model:{value:(_vm.error_notify.visible),callback:function ($$v) {_vm.$set(_vm.error_notify, "visible", $$v)},expression:"error_notify.visible"}},[_vm._v(" "+_vm._s(_vm.error_notify.text)+" ")]),_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',{class:{ disabled: _vm.loading }},[_c('v-row',[_c('v-col',{staticClass:"ml-3"},[_c('v-radio-group',{attrs:{"row":"","mandatory":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"font-size":"16px !important","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm._f("localize")("refresh_label"))+" ")])]},proxy:true}]),model:{value:(_vm.updatingRadio),callback:function ($$v) {_vm.updatingRadio=$$v},expression:"updatingRadio"}},[_c('v-radio',{attrs:{"value":"Automatic","label":_vm._f("localize")('refresh_auto_label')}}),_c('v-radio',{attrs:{"value":"Manual","label":_vm._f("localize")('refresh_manual_label')}})],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"col-12 col-sm-4"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","outlined":"","label":"Search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.supportHeaders,"items":_vm.supportItems,"items-per-page":_vm.pagination.per_page,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? item.created_at.substr(8, 2) + "-" + item.created_at.substr(5, 2) + "-" + item.created_at.substr(0, 4) : "--")+" "+_vm._s(_vm.formatTime(item.created_at))+" ")]}},{key:"item.user.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.first_name || "--")+" ")]}},{key:"item.user.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.last_name || "--")+" ")]}},{key:"item.user.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.email || "--")+" ")]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"text":"","color":"#e62076"},on:{"click":function($event){_vm.selectedItem = item;
                _vm.detailsInfoDialog = true;}}},[_vm._v(" View ")])]}},{key:"footer.prepend",fn:function(){return [_c('v-spacer')]},proxy:true}])}),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"col-6"},[_c('v-pagination',{staticClass:"mt-5",attrs:{"length":_vm.pagination.length,"color":"#6abaa4","disabled":_vm.loading},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('v-spacer')],1)],1)],1)],1),(_vm.detailsInfoDialog)?_c('details-info-dialog',{attrs:{"visible":_vm.detailsInfoDialog,"item":_vm.selectedItem},on:{"close":function($event){_vm.detailsInfoDialog = false},"changedDates":_vm.changedDates}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }