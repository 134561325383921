<template>
  <v-dialog v-model="visibility" max-width="700px">
    <v-card>
      <v-icon
        style="position: absolute; right: 10px; top: 5px"
        large
        @click="visibility = false"
        >mdi-close</v-icon
      >
      <v-card-title>Change dates ({{ item.attraction.title }})</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu_start"
              size="small"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="pa-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="pa-0 mt-1 mb-2 mr-2"
                  dense
                  :value="
                    dates.started_date
                      ? new Date(dates.started_date).toLocaleDateString(
                          'en-gb',
                          {
                            timeZone: 'Europe/London',
                          }
                        )
                      : ''
                  "
                  prepend-icon="mdi-calendar"
                  label="Started date"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  @click:prepend="on.click"
                  :error-messages="startDateError"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates.started_date"
                scrollable
                :locale="locale"
                ref="picker"
                @change="
                  $refs.menu.save(dates.started_date), (menu_start = false)
                "
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu_start = false">
                  {{ "button_cancel" | localize }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates.started_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              ref="menu"
              v-model="menu_end"
              size="small"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="pa-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="pa-0 mt-1 mb-2 mr-2"
                  dense
                  :value="
                    dates.ended_date
                      ? new Date(dates.ended_date).toLocaleDateString('en-gb', {
                          timeZone: 'Europe/London',
                        })
                      : ''
                  "
                  prepend-icon="mdi-calendar"
                  label="Ended date"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  @click:prepend="on.click"
                  :error-messages="endDateError"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates.ended_date"
                scrollable
                :locale="locale"
                ref="picker"
                @change="$refs.menu.save(dates.ended_date), (menu_end = false)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu_end = false">
                  {{ "button_cancel" | localize }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(dates.ended_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="mx-1 mb-0">
          <v-spacer />
          <v-col class="col-3">
            <v-btn
              style="text-transform: none"
              @click="onSave()"
              block
              dark
              color="#e62076"
              >Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userSettings from "@/mixins/userSettingsMixin.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SupportService from "@/requests/SupportPanel/SupportService.js";

export default {
  props: {
    visible: {
      require: true,
    },
    item: {
      require: true,
    },
  },
  mixins: [userSettings, validationMixin],
  data: () => ({
    dates: {
      started_date: "",
      ended_date: "",
    },
    menu_start: false,
    menu_end: false,
  }),
  validations() {
    return {
      dates: {
        started_date: {
          required,
        },
        ended_date: {
          required,
          minValue: (value) =>
            new Date(value) >= new Date(this.dates.started_date),
        },
      },
    };
  },
  mounted() {
    this.dates.started_date = this.item.attraction.started_at.substr(0, 10);
    this.dates.ended_date = this.item.attraction.ended_at.substr(0, 10);
  },
  methods: {
    async onSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const endDate = new Date(this.dates.ended_date);
        const params = {
          parent_uuid: this.item.citypass.parent_uuid,
          attraction_uuid: this.item.attraction.uid,
          started_at: new Date(this.dates.started_date).toISOString(),
          ended_at: endDate.toISOString().replace("00:00:00", "23:59:00"),
        };
        let res = await SupportService.setDates(params);
        console.log(res);

        this.$emit("changedDates", this.item.order.uuid);
        this.visibility = false;
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
    startDateError() {
      const errors = [];
      if (!this.$v.dates.started_date.$dirty) {
        return errors;
      }
      !this.$v.dates.started_date.required &&
        errors.push("Started date is required");
      return errors;
    },
    endDateError() {
      const errors = [];
      if (!this.$v.dates.ended_date.$dirty) {
        return errors;
      }
      !this.$v.dates.ended_date.required &&
        errors.push("Ended date is required");
      !this.$v.dates.ended_date.minValue &&
        errors.push("Ended date cannot be less than started date");
      return errors;
    },
  },
};
</script>