<template>
  <div>
    <secure-page v-if="!isSecureCheck" @onLogin="isSecureCheck = true" />
    <support-panel-main v-if="isSecureCheck" @onInvalidToken="onInvalidToken" />
  </div>
</template>

<script>
import SecurePage from "./SecurePage.vue";
import SupportPanelMain from "./SupportPanelMain.vue";

export default {
  components: {
    SecurePage,
    SupportPanelMain,
  },
  data: () => ({
    isSecureCheck: false,
  }),
  mounted() {
    if (localStorage.chambersToken) {
        this.isSecureCheck = true
    }
  },
  methods: {
    onInvalidToken() {
        localStorage.removeItem('chambersToken')
        this.isSecureCheck = false
    }
  }
};
</script>