import requestService from '../requestService';

export default {
	async getOrders(params) {
		const response = await requestService.getWithParams(`orders/chambers`, params, {
			Authorization: `Bearer ${localStorage.chambersToken}`,
		})
		return response?.data
	},
	async loginChambers(params) {
		const response = await requestService.post(`tools/chamber-auth`, params)
		return response?.data
	},
	async setDates(params) {
		const response = await requestService.put(`/orders/chambers-attraction`, params, {
			headers: {
				Authorization: `Bearer ${localStorage.chambersToken}`,
			}
		})
		return response?.data
	}
}